<template>
  <div class="account">
    <div>
      <van-field
        class="phone"
        input-align="right"
        v-model="fin_account_count"
        placeholder="请填写"
        type="number"
        clearable
        maxlength="4"
        label-width="12em"
        :formatter="formatterNumber"
        label="新申请融资子账户数量"
        :disabled="disabled"
      >
      </van-field>
      <van-field
        class="phone"
        input-align="right"
        v-model="stock_account_count"
        placeholder="请填写"
        type="number"
        clearable
        maxlength="4"
        label-width="12em"
        :formatter="formatterNumber"
        label="新申请融券子账户数量"
        :disabled="disabled"
      >
      </van-field>
    </div>
    <div style="text-align: left;padding: 20px;">
      <div style="text-align: center;" class="theme-color">子账户开立申请书</div>
      <div class="theme-color">
        本人/公司：{{name}} 身份证件号/公司注册号：{{credentials_num}}，
        现要求贵公司在本人/公司现有账户外再开设 {{Number(fin_account_count)}}
        个融资子账户和 {{Number(stock_account_count)}} 个融券子账户以方便本人/公司管理不同需求的投资，
        本人/公司明白贵公司会提供 {{Number(fin_account_count)+Number(stock_account_count)}} 个新的子账户号码，
        本人/公司明白且同意与贵公司所签署的
        交易协议同时适用于新设的 {{Number(fin_account_count)+Number(stock_account_count)}} 个子账户。
        子账户开立基于账户不同策略交易要求分开作账户管理。交易均须符合交易所制定的规则。
      </div>
      <van-cell-group title="如同意以上所有协议及申请书中内容，请签名！">
        <div class="image" @click="handleShowSignatrue">
          <van-image
            v-if="isda_sign"
            width="250px"
            height="100px"
            fit="contain"
            :src="isda_sign"
          />
          <div v-else style="height: 100px;line-height: 100px;">
            点击签名</div>
        </div>
      </van-cell-group>
    </div>
    <div style="text-align: center;">
      <van-button
        class="btn"
        type="primary"
        @click="handleSubumit"
        :disabled="!fin_account_count || !stock_account_count || !isda_sign"
      >
        提交
      </van-button>
      <van-button class="btn" @click="before">
        上一步
      </van-button>
    </div>
    <div class="signatrueMask" v-show="showSignatrue" >
      <div class="signatrue" @touchmove.stop.prevent>
        <canvas
          ref="signatrue"
          class="canvas"
        />
        <div class="btns">
          <van-button
            type="danger"
            class="btn"
            @click="handleClearSignatrue"
          >
            清除
          </van-button>
          <van-button
            type="default"
            class="btn"
            @click="handleCancelSignatrue"
          >
            取消
          </van-button>
          <van-button
            type="primary"
            class="btn"
            @click="handleOkSignatrue"
          >
            确定
          </van-button>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import {
  Button, CellGroup, Image as VanImage, Field,
} from 'vant';
import { useStore } from 'vuex';
import {
  onMounted,
  ref,
} from 'vue';
import SignaturePad from 'signature_pad';
import httpApi from '../../utils/httpApi';

export default {
  name: 'Home',
  components: {
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [VanImage.name]: VanImage,
    [Field.name]: Field,
  },
  data() {
    return {
    };
  },
  methods: {
    async handleSubumit() {
      if (this.disabled) {
        this.$router.push({ path: '/isda/search' });
      } else {
        const form = {
          isda_sign: this.isda_sign,
          fin_account_count: Number(this.fin_account_count),
          stock_account_count: Number(this.stock_account_count),
        };
        const result = await this.$api.postIsdaAccounts(form);
        if (result.code === 200) {
          if (!this.disabled) {
            this.$router.push({ path: '/isda/result' });
          } else {
            this.$router.push({ path: '/isda/search' });
          }
        } else if (result.code === 201) {
          this.$toast(result.errorMsg);
        }
      }
    },
    before() {
      this.$router.go(-1);
    },
    formatterNumber(value) {
      return value.replace(/[^0-9]+/g, '').substr(0, 4);
    },
    // 显示签名
    handleShowSignatrue() {
      if (this.disabled) return;
      this.showSignatrue = true;
      this.$refs.signatrue.width = window.innerWidth - 44;
      this.$refs.signatrue.height = window.innerHeight - 46;
      // 获取到签名数据之后执行
      this.signaturePad = new SignaturePad(
        this.$refs.signatrue, {
          minWidth: 1,
          maxWidth: 5,
        },
      );
    },

    // 签名确认
    handleOkSignatrue() {
      const data = this.signaturePad && this.signaturePad.toDataURL();
      if (this.signaturePad.isEmpty()) {
        this.isda_sign = '';
        this.showSignatrue = false;
      } else {
        this.rotateBase64Image(data);
      }
    },

    // 签名清除
    handleClearSignatrue() {
      return this.signaturePad.clear();
    },

    // 签名取消
    handleCancelSignatrue() {
      this.showSignatrue = false;
    },

    // 图片旋转
    rotateBase64Image(base64data) {
      const image = new Image();
      image.src = base64data;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.height = image.width;
        canvas.width = image.height;
        const ctx = canvas.getContext('2d');
        ctx.translate(Math.floor(image.height / 2), Math.floor(image.width / 2));
        ctx.rotate((-90 * Math.PI) / 180);
        ctx.drawImage(image, -image.width / 2, -image.height / 2);
        this.showSignatrue = false;
        this.isda_sign = canvas.toDataURL();
      };
    },

  },
  setup() {
    const showSignatrue = ref(false);
    const signaturePad = null;

    const isda_sign = ref('');
    const fin_account_count = ref('');
    const stock_account_count = ref('');
    const name = ref('');
    const credentials_num = ref('');
    const disabled = ref(true);

    onMounted(async () => {
      const store = useStore();
      store.commit('setTitleValue', 'ISDA协议');
      store.commit('setTitleLeft', true);
      store.commit('setStepsShow', false);
      disabled.value = await httpApi.getIsdaDisabled();
      const result = await httpApi.getIsdaAccounts();
      if (!result) return;
      isda_sign.value = result.data.isda_sign;
      fin_account_count.value = result.data.fin_account_count || '';
      stock_account_count.value = result.data.stock_account_count || '';
      name.value = result.data.name;
      credentials_num.value = result.data.credentials_num;
    });

    return {
      disabled,
      isda_sign,
      fin_account_count,
      stock_account_count,
      name,
      credentials_num,
      showSignatrue,
      signaturePad,
    };
  },
};
</script>

<style lang="less">
  .account {
    text-align: left;

    .content {
      margin-top: @space-2;
      padding: @space-2;
      background: @contentColor;
      font-size: @font-size-2;
    }

    .title {
      text-align: center;
    }

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: @space-2 0;
    }

    .signatrueMask {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 9999;
      display: flex;

      .signatrue {
        position: relative;
        flex: 1;
        background-color: white;

        .canvas {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 44px;
        }

        .btns {
          position: absolute;
          top: 50px;
          left: 0;
          right: 0;
          display: flex;
          transform:rotate(90deg);
          transform-origin: left bottom;

          .btn {
            width: 50%;
            margin: 0 @space-1;
          }
        }
      }
    }
    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }
  }

</style>
